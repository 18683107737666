var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "cat-container" },
    [
      _c("Header", {
        attrs: {
          title: _vm.txt.pageTitle,
          subtitle: _vm.txt.pageSubtitle,
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasAddButton: false,
          hasUpdateInfo: false,
        },
      }),
      _c(
        "v-card",
        [
          _c("FilterEncapsulated"),
          _c("AlertBox", {
            staticClass: "mt-4",
            attrs: {
              text: "poderia ser passado como prop",
              type: "warning",
              hasExtraContainer: true,
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _c("span", { staticClass: "cat-alert-title" }, [
                      _vm._v(
                        " Título alerta! precisou ser colocado no slot para estilizar o negrito "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "container-extra",
                fn: function () {
                  return [
                    _vm._v(" html do container extra do alerta aqui "),
                    _c(
                      "AGTooltip",
                      {
                        attrs: {
                          largeTooltip: true,
                          largeTooltipTitle: "titulo tooltip",
                          largeTooltipIcon: "help",
                          largeTooltipIconClass: "material-symbols-outlined",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "largeTooltipContent",
                            fn: function () {
                              return [_vm._v(" tooltip text ")]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [[_vm._v(" saiba mais ")]],
                      2
                    ),
                    _c("v-btn", { staticClass: "btn-ag-tertiary" }, [
                      _vm._v(" Alert Button "),
                    ]),
                    _c("v-btn", { staticClass: "btn-ag-secondary" }, [
                      _vm._v(" Alert Button "),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("GraphsContainer", {
            attrs: {
              title: _vm.txt.graphsContainerTitle,
              disabled: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "graphs",
                fn: function () {
                  return [
                    _c(
                      "v-card",
                      { staticClass: "pa-0" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-end px-4 pt-4",
                          },
                          [
                            _c(
                              "Tag",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  outlined: "",
                                  clickable: "",
                                  active: _vm.isOverview,
                                },
                                on: { click: _vm.changeView },
                              },
                              [_vm._v("Visão geral")]
                            ),
                            _c(
                              "Tag",
                              {
                                staticClass: "ml-4",
                                attrs: {
                                  outlined: "",
                                  clickable: "",
                                  active: !_vm.isOverview,
                                },
                                on: { click: _vm.changeView },
                              },
                              [_vm._v("Últimos meses")]
                            ),
                          ],
                          1
                        ),
                        _vm.isOverview
                          ? [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-row pa-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column flex-grow-1",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c("div", [
                                                _c("div", [
                                                  _vm._v(
                                                    " total de acidentes "
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  { staticClass: "cat-total" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.valueFormatSeparator(
                                                            9500
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column align-self-center ml-8",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c("v-badge", {
                                                        attrs: {
                                                          inline: "",
                                                          left: "",
                                                          dot: "",
                                                          color: "red",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "Com afastamento "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-4" },
                                                    [
                                                      _c("v-badge", {
                                                        attrs: {
                                                          inline: "",
                                                          left: "",
                                                          dot: "",
                                                          color: "green",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "Sem afastamento "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "d-flex flex-row" },
                                            [
                                              _c("DataDisplay", {
                                                staticClass: "ml-8",
                                                attrs: {
                                                  title: "Dias de afastamento",
                                                  value:
                                                    _vm.valueFormatSeparator(
                                                      "56"
                                                    ),
                                                  icon: "event",
                                                },
                                              }),
                                              _c(
                                                "DataDisplay",
                                                {
                                                  staticClass: "ml-8 mr-4",
                                                  attrs: {
                                                    title: "Envios do S-2230",
                                                    value: "250/312",
                                                    icon: "document_scanner",
                                                    hasExtraContainer: "",
                                                    hasHelper: "",
                                                    helpText: "teste",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " texto extra sem estilo "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("GraphLinePercentual", {
                                        staticClass: "mr-4 my-4",
                                        attrs: { values: _vm.totalAccident },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "cat-death-display d-flex align-center",
                                    },
                                    [
                                      _c("DataDisplay", {
                                        staticClass: "ml-4 mr-4",
                                        attrs: {
                                          title: "Óbitos",
                                          value: _vm.valueFormatSeparator("56"),
                                          icon: "event",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "cat-position-inherit cat-card-border-fix",
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-0 cat-position-inherit",
                                    },
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          model: {
                                            value: _vm.tabOverviewSelected,
                                            callback: function ($$v) {
                                              _vm.tabOverviewSelected = $$v
                                            },
                                            expression: "tabOverviewSelected",
                                          },
                                        },
                                        [
                                          _c("v-tabs-slider"),
                                          _c(
                                            "v-tab",
                                            {
                                              attrs: {
                                                value: "categorizacao",
                                                id: "categorizacao_tab",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "tab-text" },
                                                [_vm._v("Categorização")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-tab",
                                            {
                                              attrs: {
                                                value: "",
                                                id: "horas_trabalhadas_tab",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "tab-text" },
                                                [
                                                  _vm._v(
                                                    "Horas trabalhadas e Acidentes por turno"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          staticClass: "cat-position-inherit",
                                          model: {
                                            value: _vm.tabOverviewSelected,
                                            callback: function ($$v) {
                                              _vm.tabOverviewSelected = $$v
                                            },
                                            expression: "tabOverviewSelected",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            {
                                              staticClass:
                                                "cat-position-inherit",
                                            },
                                            [
                                              _c(
                                                "v-container",
                                                {
                                                  staticClass:
                                                    "pa-4 cat-intern-card-container",
                                                  attrs: { fluid: "" },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "d-flex flex-row cat-intern-card-row",
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "4 pr-2",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "cat-intern-card",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Acidentes por tipo "
                                                              ),
                                                              _c(
                                                                "DataDisplay",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Doença ocupacional",
                                                                    value:
                                                                      _vm.valueFormatSeparator(
                                                                        "3521"
                                                                      ),
                                                                    icon: "health_and_safety",
                                                                    iconClass:
                                                                      "material-icons-outlined",
                                                                    hasExtraContainer:
                                                                      "",
                                                                    helpText:
                                                                      "teste",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "GraphLinePercentual",
                                                                    {
                                                                      attrs: {
                                                                        values:
                                                                          _vm.daysOff,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "DataDisplay",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Doença ocupacional",
                                                                    value:
                                                                      _vm.valueFormatSeparator(
                                                                        "3521"
                                                                      ),
                                                                    icon: "health_and_safety",
                                                                    iconClass:
                                                                      "material-icons-outlined",
                                                                    iconColor:
                                                                      "#3C73C7",
                                                                    hasExtraContainer:
                                                                      "",
                                                                    helpText:
                                                                      "teste",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "GraphLinePercentual",
                                                                    {
                                                                      attrs: {
                                                                        values:
                                                                          _vm.daysOff,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "DataDisplay",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Doença ocupacional",
                                                                    value:
                                                                      _vm.valueFormatSeparator(
                                                                        "3521"
                                                                      ),
                                                                    icon: "health_and_safety",
                                                                    iconClass:
                                                                      "material-icons-outlined",
                                                                    iconColor:
                                                                      "#3C73C7",
                                                                    hasExtraContainer:
                                                                      "",
                                                                    helpText:
                                                                      "teste",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "GraphLinePercentual",
                                                                    {
                                                                      attrs: {
                                                                        values:
                                                                          _vm.daysOff,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "px-2",
                                                          attrs: { cols: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-column",
                                                            },
                                                            [
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "cat-intern-card mb-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "DataDisplay",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "Doença ocupacional",
                                                                        value:
                                                                          _vm.valueFormatSeparator(
                                                                            "3521"
                                                                          ),
                                                                        icon: "health_and_safety",
                                                                        iconClass:
                                                                          "material-icons-outlined",
                                                                        iconColor:
                                                                          "#3C73C7",
                                                                        hasExtraContainer:
                                                                          "",
                                                                        helpText:
                                                                          "teste",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "GraphLinePercentual",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              values:
                                                                                _vm.daysOff,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "DataDisplay",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "Doença ocupacional",
                                                                        value:
                                                                          _vm.valueFormatSeparator(
                                                                            "3521"
                                                                          ),
                                                                        icon: "health_and_safety",
                                                                        iconClass:
                                                                          "material-icons-outlined",
                                                                        iconColor:
                                                                          "#3C73C7",
                                                                        hasExtraContainer:
                                                                          "",
                                                                        helpText:
                                                                          "teste",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "GraphLinePercentual",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              values:
                                                                                _vm.daysOff,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-card",
                                                                {
                                                                  staticClass:
                                                                    "cat-intern-card mt-2",
                                                                },
                                                                [
                                                                  _c(
                                                                    "DataDisplay",
                                                                    {
                                                                      attrs: {
                                                                        title:
                                                                          "Doença ocupacional",
                                                                        value:
                                                                          _vm.valueFormatSeparator(
                                                                            "3521"
                                                                          ),
                                                                        icon: "health_and_safety",
                                                                        iconClass:
                                                                          "material-icons-outlined",
                                                                        iconColor:
                                                                          "#3C73C7",
                                                                        hasExtraContainer:
                                                                          "",
                                                                        helpText:
                                                                          "teste",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("div", [
                                                                    _vm._v(
                                                                      " graph % "
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "pl-2",
                                                          attrs: { cols: "4" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "cat-intern-card cat-emited-graph",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "titulo"
                                                                ),
                                                              ]),
                                                              _c("GraphDonut", {
                                                                attrs: {
                                                                  options:
                                                                    _vm.emitedCatGraphOptions,
                                                                  series:
                                                                    _vm.emitedCatGraphSeries,
                                                                  height:
                                                                    "350px",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-tab-item", [_vm._v(" 2 ")]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _c(
                              "div",
                              { staticClass: "pa-4" },
                              [
                                _c(
                                  "v-tabs",
                                  {
                                    model: {
                                      value: _vm.tabLastMonthsSelected,
                                      callback: function ($$v) {
                                        _vm.tabLastMonthsSelected = $$v
                                      },
                                      expression: "tabLastMonthsSelected",
                                    },
                                  },
                                  [
                                    _c("v-tabs-slider"),
                                    _c(
                                      "v-tab",
                                      {
                                        attrs: {
                                          value: "retirement_death",
                                          id: "retirementDeath_tab",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "tab-text" }, [
                                          _vm._v("Afastamento e óbito"),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-tab",
                                      {
                                        attrs: {
                                          value: "gender",
                                          id: "gender_tab",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "tab-text" }, [
                                          _vm._v("Gênero"),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-tab",
                                      {
                                        attrs: {
                                          value: "special_retirement",
                                          id: "special_retirement_tab",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "tab-text" }, [
                                          _vm._v("Aposentadoria especial"),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-tab",
                                      {
                                        attrs: {
                                          value: "gender",
                                          id: "gender_tab",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "tab-text" }, [
                                          _vm._v("Gênero"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tabs-items",
                                  {
                                    model: {
                                      value: _vm.tabLastMonthsSelected,
                                      callback: function ($$v) {
                                        _vm.tabLastMonthsSelected = $$v
                                      },
                                      expression: "tabLastMonthsSelected",
                                    },
                                  },
                                  [_c("v-tab-item")],
                                  1
                                ),
                                _c("div", [
                                  _vm._v(
                                    " Tab / Gráfico de linha / Carrousel "
                                  ),
                                ]),
                              ],
                              1
                            ),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "v-card",
            { staticClass: "cat-card-table" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-row justify-space-between cat-card-table-header",
                },
                [
                  _c("TableSearch", {
                    attrs: { label: _vm.txt.tableSearchLabel },
                  }),
                  _c("ExportButton", { on: { click: _vm.exportTable } }),
                ],
                1
              ),
              _c("TableCAT", {
                attrs: {
                  loadingTable: _vm.loadingTable,
                  tableTitle: _vm.txt.tableTitle,
                  headers: _vm.tableCatHeader,
                  items: _vm.tableData,
                  paginationFilter: {},
                  totalItems: 10,
                },
                on: { setPagination: _vm.setPagination },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }