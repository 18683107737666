<template>
    <v-main class="cat-container">
        <Header 
            :title="txt.pageTitle"
            :subtitle="txt.pageSubtitle"
            :itemsBreadCrumb="itemsBreadCrumb"
            :hasAddButton="false"
            :hasUpdateInfo="false">
        </Header>
        <v-card>
            <FilterEncapsulated>
                
            </FilterEncapsulated>
            <AlertBox text="poderia ser passado como prop" class="mt-4" type="warning" :hasExtraContainer="true">
                <template v-slot:title>
                    <span class="cat-alert-title">
                        Título alerta! precisou ser colocado no slot para estilizar o negrito
                    </span>
                </template>
                <template v-slot:container-extra>
                    html do container extra do alerta aqui
                    <AGTooltip 
                        :largeTooltip="true"
                        largeTooltipTitle="titulo tooltip"
                        largeTooltipIcon="help"
                        largeTooltipIconClass="material-symbols-outlined"
                    >
                        <template>
                            saiba mais
                        </template>
                        <template v-slot:largeTooltipContent>
                            tooltip text
                        </template>
                    </AGTooltip>
                    <v-btn class="btn-ag-tertiary"> Alert Button </v-btn>
                    <v-btn class="btn-ag-secondary"> Alert Button </v-btn>
                </template>
            </AlertBox>
            <GraphsContainer :title="txt.graphsContainerTitle" :disabled='loading'>
                <template v-slot:graphs>
                    <v-card class="pa-0">
                        <div class="d-flex flex-row justify-end px-4 pt-4">
                            <Tag class="ml-4" outlined clickable :active="isOverview" @click="changeView">Visão geral</Tag>
                            <Tag class="ml-4" outlined clickable :active="!isOverview" @click="changeView">Últimos meses</Tag>
                        </div>
                        <template v-if="isOverview">
                            <div class="d-flex flex-row pa-4">
                                <div class="d-flex flex-column flex-grow-1">
                                    <div class="d-flex flex-row">
                                        <div class="d-flex flex-row">
                                            <div>
                                                <div>
                                                    total de acidentes
                                                </div>
                                                <div class="cat-total">
                                                    {{ valueFormatSeparator(9500) }}
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column align-self-center ml-8">
                                                <div>
                                                    <v-badge inline left dot color="red"></v-badge>Com afastamento
                                                </div>
                                                <div class="mt-4">
                                                    <v-badge inline left dot color="green"></v-badge>Sem afastamento
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <DataDisplay class="ml-8" title="Dias de afastamento"
                                                :value="valueFormatSeparator('56')" icon="event">
                                            </DataDisplay>
                                            <DataDisplay class="ml-8 mr-4" title="Envios do S-2230" value="250/312"
                                                icon="document_scanner" hasExtraContainer hasHelper helpText="teste">
                                                texto extra sem estilo
                                            </DataDisplay>
                                        </div>
                                    </div>
                                    <GraphLinePercentual :values="totalAccident" class="mr-4 my-4" />
                                </div>
                                <div class="cat-death-display d-flex align-center">
                                    <DataDisplay class="ml-4 mr-4" title="Óbitos" :value="valueFormatSeparator('56')"
                                        icon="event">
                                    </DataDisplay>
                                </div>
                            </div>
                            <v-card class="cat-position-inherit cat-card-border-fix">
                                <v-card class="pa-0 cat-position-inherit">
                                <v-tabs v-model="tabOverviewSelected">
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab value="categorizacao" id="categorizacao_tab">
                                        <div class="tab-text">Categorização</div>
                                    </v-tab>
                                    <v-tab value="" id="horas_trabalhadas_tab">
                                        <div class="tab-text">Horas trabalhadas e Acidentes por turno</div>
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tabOverviewSelected" class="cat-position-inherit">
                                    <v-tab-item class="cat-position-inherit">
                                        <v-container fluid class="pa-4 cat-intern-card-container">
                                            <v-row class="d-flex flex-row cat-intern-card-row" no-gutters>
                                                <v-col cols="4 pr-2">
                                                    <v-card class="cat-intern-card">
                                                        Acidentes por tipo
                                                        <DataDisplay title="Doença ocupacional"
                                                            :value="valueFormatSeparator('3521')" icon="health_and_safety"
                                                            iconClass="material-icons-outlined" hasExtraContainer
                                                            helpText="teste">
                                                            <GraphLinePercentual :values="daysOff" />
                                                        </DataDisplay>
                                                        <DataDisplay title="Doença ocupacional"
                                                            :value="valueFormatSeparator('3521')" icon="health_and_safety"
                                                            iconClass="material-icons-outlined" iconColor="#3C73C7"
                                                            hasExtraContainer helpText="teste">
                                                            <GraphLinePercentual :values="daysOff" />
                                                        </DataDisplay>
                                                        <DataDisplay title="Doença ocupacional"
                                                            :value="valueFormatSeparator('3521')" icon="health_and_safety"
                                                            iconClass="material-icons-outlined" iconColor="#3C73C7"
                                                            hasExtraContainer helpText="teste">
                                                            <GraphLinePercentual :values="daysOff" />
                                                        </DataDisplay>

                                                    </v-card>
                                                </v-col>
                                                <v-col cols="4" class="px-2">
                                                    <div class="d-flex flex-column">
                                                        <v-card class="cat-intern-card mb-2">
                                                            <DataDisplay title="Doença ocupacional"
                                                                :value="valueFormatSeparator('3521')"
                                                                icon="health_and_safety" iconClass="material-icons-outlined"
                                                                iconColor="#3C73C7" hasExtraContainer helpText="teste">
                                                                <GraphLinePercentual :values="daysOff" />
                                                            </DataDisplay>
                                                            <DataDisplay title="Doença ocupacional"
                                                                :value="valueFormatSeparator('3521')"
                                                                icon="health_and_safety" iconClass="material-icons-outlined"
                                                                iconColor="#3C73C7" hasExtraContainer helpText="teste">
                                                                <GraphLinePercentual :values="daysOff" />
                                                            </DataDisplay>
                                                        </v-card>
                                                        <v-card class="cat-intern-card mt-2">
                                                            <DataDisplay title="Doença ocupacional"
                                                                :value="valueFormatSeparator('3521')"
                                                                icon="health_and_safety" iconClass="material-icons-outlined"
                                                                iconColor="#3C73C7" hasExtraContainer helpText="teste">
                                                            </DataDisplay>
                                                            <div>
                                                                graph %
                                                            </div>
                                                        </v-card>
                                                    </div>
                                                </v-col>
                                                <v-col cols="4" class="pl-2">
                                                    <v-card class="cat-intern-card cat-emited-graph">
                                                        <div>titulo</div>
                                                        <GraphDonut :options='emitedCatGraphOptions'
                                                            :series="emitedCatGraphSeries" height="350px" />
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>

                                    <v-tab-item>
                                        2
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card>
                        </v-card>
                        </template>
                        <div v-else class="pa-4">
                            <v-tabs v-model="tabLastMonthsSelected">
                                <v-tabs-slider></v-tabs-slider>
                                <v-tab value="retirement_death" id="retirementDeath_tab">
                                    <div class="tab-text">Afastamento e óbito</div>
                                </v-tab>
                                <v-tab value="gender" id="gender_tab">
                                    <div class="tab-text">Gênero</div>
                                </v-tab>
                                <v-tab value="special_retirement" id="special_retirement_tab">
                                    <div class="tab-text">Aposentadoria especial</div>
                                </v-tab>
                                <v-tab value="gender" id="gender_tab">
                                    <div class="tab-text">Gênero</div>
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tabLastMonthsSelected">
                                <v-tab-item>
                                    
                                </v-tab-item>
                            </v-tabs-items>
                            <div>
                                Tab / Gráfico de linha / Carrousel
                            </div>
                        </div>
                    </v-card>
                </template>
            </GraphsContainer>
            <v-card class="cat-card-table">
                <div class="d-flex flex-row justify-space-between cat-card-table-header">
                    <TableSearch :label="txt.tableSearchLabel"></TableSearch>
                    <ExportButton @click="exportTable"></ExportButton>
                </div>
                <TableCAT
                    :loadingTable="loadingTable"
                    :tableTitle="txt.tableTitle"
                    :headers="tableCatHeader"
                    :items="tableData"
                    :paginationFilter="{}"
                    :totalItems="10"
                    @setPagination="setPagination"
                >
                </TableCAT>
            </v-card>
        </v-card>
    </v-main>
</template>

<script>

export default {
    name: "CAT",
    components: {
        Header: () => import('../commons/Header.vue'),
        FilterEncapsulated: () => import('../commons/FilterEncapsulated.vue'),
        GraphsContainer: () => import('../commons/GraphsContainer.vue'),
        TableSearch: () => import('../commons/TableSearch.vue'),
        ExportButton: () => import('../commons/ExportButton.vue'),
        AlertBox: () => import('../commons/AlertBox.vue'),
        AGTooltip: () => import('../commons/AGTooltip.vue'),
        GraphDonut: () => import('../commons/GraphDonut.vue'),
        DataDisplay: () => import('../commons/DataDisplay.vue'),
        GraphLinePercentual: () => import('../commons/GraphLinePercentual.vue'),
        Tag: () => import('../commons/Tag.vue'),
        TableCAT: () => import('./components/TableCAT.vue')
    },
    data() {
        return {
            itemsBreadCrumb: [{
                title: 'Home',
                disabled: false,
                href: 'home',
            },
            {
                title: 'War Smart Solutions',
                disabled: true,
            },
            {
                title: 'CAT',
                disabled: true,
                active: true
            }],
            txt: {
                pageTitle: "Comunicação de acidentes de trabalho",
                pageSubtitle: "Monitoramento de comunicação de acidentes de trabalho do colaborador",
                graphsContainerTitle: "<strong>Gráficos por categorização e horas</strong>",
                tableSearchLabel: "pesquisa por...",
                tableTitle: 'Comunicados'
            },
            isOverview: true,
            tabOverviewSelected: 0,
            tabLastMonthsSelected: 0,
            loadingTable: true,
            tableCatHeader: [
                { text: '', value: 'id_column', width: '50px', sortable: false },
                { text: 'Empregador', value: 'employer', width: 'auto', sortable: true },
                { text: 'CPF', value: 'cpf', width: 'auto', sortable: true },
                { text: 'Nome', value: 'name', width: 'auto', sortable: true },
                { text: 'Matrícula', value: 'matriculation', width: 'auto', sortable: true },
                { text: 'Tipo de acidente', value: 'tipo_acidente', width: 'auto', sortable: true },
                { text: 'Data/Hora do acidente', value: 'accident_date', width: 'auto', sortable: true },
                { text: 'Afastamento', value: 'absence', width: 'auto', sortable: true },
                { text: 'Tipo de CAT', value: 'cat_type', width: 'auto', sortable: true },
                { text: 'Registro do evento', value: 'event_registration', width: 'auto', sortable: true },
                { text: 'PCD', value: 'pcd', width: 'auto', sortable: true },
                { text: 'Gênero', value: 'gender', width: 'auto', sortable: true },
                { text: 'Aposentadoria Especial - FAE', value: 'fae', width: 'auto', sortable: true },
                { text: 'Declaração', value: 'declaration', width: 'auto', sortable: true },
                { text: 'Transmissão', value: 'transmition', width: 'auto', sortable: true },
                { text: 'Grupo de categoria', value: 'category_group', width: 'auto', sortable: true },
                { text: 'Categoria', value: 'category', width: 'auto', sortable: true },
                { text: 'Regime previdenciário', value: 'pension_regime', width: 'auto', sortable: true },
                { text: 'CBO', value: 'cbo', width: 'auto', sortable: true },
                { text: 'Cargo', value: 'role', width: 'auto', sortable: true },
                { text: 'Ações', value: 'action', width: 'auto', sortable: false, class: 'table-action-header', cellClass: 'table-action-cell'}
            ],
            //dados mockados @todo remover depois
            loading: true,
            daysOff: [
                {
                    percentual: 25,
                    total: 100,
                    color: '#442D96'
                }
            ],
            totalAccident: [
                {
                    percentual: 25,
                    total: 100,
                    color: '#E5D545'
                },
                {
                    percentual: 75,
                    total: 100,
                    color: '#442d96'
                }
            ],
            emitedCatGraphLabels: [
                '25 anos - Alíquota FAE de 6% (xxxx)',
                '20 anos - Alíquota FAE de 9% (xxxx)',
                '15 anos - Alíquota FAE de 12% (xxxx)',
                'Ausente (xxxx)'
            ],
            emitedCatGraphSeries: [50, 60, 50, 100],
            tableData: [
            { 
                    id_column: 1,
                    id: 1,
                    employer: '',
                    employer_cnpj: '',
                    cpf: '',
                    name: '',
                    matriculation: "",
                    accident_type: "",
                    accident_date: "",
                    absence: "",
                    cat_type: "",
                    event_registration: "",
                    pcd: "",
                    gender: "",
                    fae: "",
                    declaration: "",
                    transmition: "",
                    category_group: "",
                    category: "",
                    pension_regime: "",
                    cbo: "",
                    role: "",
                },
                { 
                    id_column: 2,
                    id: 2,
                    employer: 'GOVERNO DO ESTADO DE SERRACOSTA',
                    employer_cnpj: '',
                    cpf: '02438558342',
                    name: 'SILVANA SAMPAIO BARBOSA',
                    matriculation: "342365-4",
                    accident_type: "Típico",
                    accident_date: "30/03/2024 - 08:00",
                    absence: "Não",
                    cat_type: "Inicial",
                    event_registration: "10/04/2024",
                    pcd: "Sim",
                    gender: "Feminino",
                    fae: "Ausente",
                    declaration: "Original",
                    transmition: "Fora do Prazo",
                    category_group: "Agente Público",
                    category: "301 - Servidor público titular de cargo efetivo,",
                    pension_regime: "Regime Geral de Previdência Social - RGPS",
                    cbo: "914405 - Mecânico",
                    role: "Mecânico",
                },
            ]
        }
        //fim dados mockados
    },
    computed: {
        emitedCatGraphOptions() {
            let options = {
                height: '100%',
                legend: {
                    show: true,
                    position: 'bottom',
                    onItemHover: {
                        highlightDataSeries: false
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },
                },
                colors: ['#E5D545', '#C73C44', '#3C73C7', '#E7E7FA']
            };

            options['labels'] = this.emitedCatGraphLabels;

            return options;
        }
    },
    mounted() {

        //SIMULA LOADING DOS DADOS @todo remover depois
        setTimeout(() => {
            this.loading = false;
            this.loadingTable = false;
        }, 1000);
    },
    methods: {
        exportTable() {
            console.log("EXPORT TABLE CLICK");
        },
        valueFormatSeparator(value) {
            return parseInt(value).toLocaleString('pt-BR');
        },
        changeView() {
            this.isOverview = !this.isOverview;
        },
        setPagination(pagination) {
            // this.setColaboradoresPagination({ pagination: pagination, term: this.tableAfastadosSearch });
        },
    }
}

</script>

<style scoped lang="scss">
@import './src/design/variables.scss';

.cat-container {

    .cat-total {
        color: $neutral-color-high-medium;
        font-size: 80px;
        line-height: 100%;
        letter-spacing: 0.8px;
    }
    .cat-alert-title {
        font-weight: $font-weight-bold;
        margin-right: $spacing-02;
    }

    .cat-death-display {
        border-left: 1px solid $neutral-color-low-dark;
    }

    .cat-intern-card-container {
        position: inherit;

        .cat-intern-card-row {
            position: inherit;

            .cat-intern-card {
                background-color: $neutral-color-low-medium !important;
                position: inherit;
            }
        }
    }

    .cat-position-inherit {
        position: inherit;
    }

    .cat-emited-graph {
        height: 100%;
    }

    .cat-card-border-fix {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-left-width: 0 !important;
        border-right-width: 0 !important;
        border-bottom-width: 0 !important;
    }

    .cat-card-table {
        margin-top: $spacing-05;
        padding: 0;
        .cat-card-table-header {
            padding: $spacing-05;
        }
    }
}
</style>